import "./Map.css";
import React, { useEffect, useRef, useState } from "react";
import MapGL, {
  GeolocateControl,
  Marker,
  NavigationControl,
  Popup,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import Typography from "@mui/material/Typography";
import { getFirestore, collection, query, getDocs } from "firebase/firestore";

mapboxgl.workerClass = require("mapbox-gl/dist/mapbox-gl-csp-worker").default;
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

function Map(props) {
  const [showPopup, setShowPopup] = useState(null);
  const [showNameApproved, setShowNameApproved] = useState(false);
  const [locations, setLocations] = useState([]);
  const [viewport, setViewport] = useState({
    longitude: -110.911789,
    latitude: 32.284,
    zoom: 10,
  });
  const firestore = getFirestore();
  const collections = query(collection(firestore, "Awe Uploads"));

  const getLocations = async () => {
    const data = await getDocs(collections);
    var getLocationsArray = data.docs.map((doc) => ({ ...doc.data() }));
    var newLocationsArray = getLocationsArray.filter(
      (location) =>
        location.showOnMap &&
        location.latitude !== null &&
        location.longitude !== null
    );
    setLocations(newLocationsArray);
  };

  useEffect(() => {
    getLocations();
  }, []);

  const handleMarkerClick = (location) => {
    setViewport({
      longitude: location.longitude,
      latitude: location.latitude,
      zoom: 12,
    });
    setShowPopup(location);
    if (location.showName == true) {
      setShowNameApproved(true);
    }
  };

  const mapRef = useRef(null);
  // other state variables and functions

  return (
    <>
      <div className="map">
        <MapGL
          onClick={() => {
            if (showPopup !== null) {
              setShowPopup(null);
            }
          }}
          width="100vw"
          height="100vh"
          mapboxApiAccessToken={mapboxgl.accessToken}
          mapStyle="mapbox://styles/joshtornqu/cl852ic40000t16o8dvy97n61"
          dragPan={true}
          initialViewState={viewport}
          dragRotate={false}
          scrollZoom={true}
          touchZoom={true}
          transitionDuration={500}>
          <NavigationControl />
          {locations.map((location) => (
            <Marker
              key={location.email + "_" + location.title}
              latitude={location.latitude + Math.random() / 10000}
              longitude={location.longitude + Math.random() / 10000}
              zoom={5}>
              <button
                className="marker-button"
                onClick={(event) => {
                  event.stopPropagation(); // prevent click event from propagating to MapGL
                  handleMarkerClick(location);
                }}>
                <img className="pinImage" src={location.file} />
              </button>
            </Marker>
          ))}

          {showPopup ? (
            <Popup
              latitude={showPopup.latitude}
              longitude={showPopup.longitude}
              key={showPopup.key}
              closeButton={true}
              closeOnClick={false}
              onClose={() => {
                setShowPopup(null);
              }}
              className="card-style"
              anchor="left">
              <img className="image-style" src={showPopup.file} />
              {/* POPUP TITLE */}
              <Typography
                paddingLeft="10px"
                paddingRight="10px"
                color="white"
                fontWeight="700"
                fontSize="25px"
                fontFamily={"Nunito"}
                fontStyle={"sans-serif"}>
                {showPopup.description}
              </Typography>

              {/* POPUP NAME */}
              <Typography
                gutterBottom
                paddingLeft="10px"
                marginTop="-5px"
                color="white"
                fontWeight="500"
                fontSize="12px"
                fontFamily={"Nunito"}
                fontStyle={"sans-serif"}>
                {showNameApproved && <i>{showPopup.name}</i>}
              </Typography>

              <br></br>
              {/* POPUP DIRECTIONS */}
              <Typography
                gutterBottom
                paddingLeft="10px"
                paddingRight="10px"
                variant="h6"
                color="white"
                fontWeight="300"
                fontSize="12px"
                fontFamily={"Nunito"}
                fontStyle={"sans-serif"}>
                <b></b>&nbsp;
                {showPopup.location}
              </Typography>

              {/* POPUP TAGS */}
              <Typography
                paddingLeft="10px"
                color="#B3B3B3"
                fontWeight="700"
                fontSize="12px"
                fontFamily={"Nunito"}
                fontStyle={"sans-serif"}>
                <div className="map-modal-tags-wrapper">
                  {showPopup?.tags.map((tag, index) => (
                    <div className="map-modal-tag" key={index}>
                      {tag}&nbsp;
                    </div>
                  ))}
                </div>
              </Typography>
            </Popup>
          ) : null}
        </MapGL>
      </div>
    </>
  );
}

export default Map;
