import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes } from 
'react-router-dom';
import './App.css';
import Homepage from './components/Homepage/Homepage';
import Upload from './components/Upload/Upload';
import Map from './components/Map/Map';
import Navbar from "./components/Navbar/Navbar"
import Gallery from './components/Gallery/Gallery';

function App() {
  return (

        <>
        <Navbar/>
          <Routes>
          <Route exact path='/' element={<Homepage/>}/>
          <Route path='/home' element={<Homepage/>}/>
          <Route path='/upload' element={<Upload/>}/>
          <Route path='/map' element={<Map/>}/>
          <Route path='/gallery' element={<Gallery/>}/>
          </Routes>
          
        </> 
    
      );


}

export default App;

