import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Upload.css";
import { doc, setDoc, getFirestore } from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import { findRenderedDOMComponentWithClass } from "react-dom/test-utils";
// import { Client } from "@notionhq/client"
import LoadingIcons from "react-loading-icons";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { height, style } from "@mui/system";

function Upload(props) {
  const db = getFirestore();
  const storage = getStorage();

  // // Each value from the form
  const [tags, setTags] = React.useState([]);

  const selectedTags = (tags) => console.log(tags);
  const [imagePreview, setImagePreview] = useState(null);
  const [file, setFile] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);

  // Sub-themes
  const [light, setLight] = useState(null);
  const [color, setColor] = useState(null);
  const [texturePattern, setTexturePattern] = useState(null);
  const [scale, setScale] = useState(null);
  const [nature, setNature] = useState(null);

  const [location, setLocation] = useState(null);
  const [locationPackage, setLocationPackage] = useState({});

  const [directions, setInstructions] = useState(null);
  const [loading, setLoading] = useState(<></>);
  const [name, setName] = useState("Anonymous");
  const [email, setEmail] = useState(null);
  const [description, setDescription] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [mediaPreview, setMediaPreview] = useState(null);
  const [theme, setTheme] = useState(null);
  const [subtheme, setSubTheme] = useState(null);
  const [subThemeJSX, setSubThemeJSX] = useState(<></>);
  const [showName, setShowName] = useState(false);
  const [showOnMap, setShowOnMap] = useState(false);
  const [cityStateInput, setCityStateInput] = useState(false);
  const [buttonColorYES, setButtonColorYES] = useState("#808080");
  const [buttonColorNO, setButtonColorNO] = useState("#808080");
  const [locationOptionShow, setLocationOptionShow] = useState(false);
  const [cityCountry, setCityCountry] = useState(null);
  var status = false;

  function subthemeLight() {
    return (
      <select
        name="subtheme-light"
        id="subtheme-light"
        onChange={(e) => setSubTheme(e.target.value)}>
        <option value="select">Sub-theme (optional)</option>
        <option value="dawn">Dawn</option>
        <option value="morning">Morning</option>
        <option value="afternoon">Afternoon</option>
        <option value="sunset">Sunset</option>
        <option value="dusk">Dusk</option>
        <option value="night-time">Night-time</option>
        <option value="shadow">Shadow</option>
        <option value="reflection">Reflection</option>
        <option value="sky">Sky</option>
        <option value="other">Other</option>
      </select>
    );
  }

  function subthemeColor() {
    return (
      <select
        name="subtheme-color"
        id="subtheme-color"
        onChange={(e) => setSubTheme(e.target.value)}>
        <option value="select">Sub-theme (optional)</option>
        <option value="red">Red</option>
        <option value="orange">Orange</option>
        <option value="yellow">Yellow</option>
        <option value="green">Green</option>
        <option value="blue">Blue</option>
        <option value="purple">Purple</option>
        <option value="pink">Pink</option>
        <option value="black">Black</option>
        <option value="white">White</option>
        <option value="white">Black & White</option>
        <option value="grey">Grey</option>
        <option value="brown">Brown</option>
        <option value="multicolored">Multicolored</option>
        <option value="other">Other</option>
      </select>
    );
  }

  function subthemeTexturePattern() {
    return (
      <select
        name="subtheme-texture-pattern"
        id="subtheme-texture-pattern"
        setSubTheme={(e) => setTexturePattern(e.target.value)}>
        <option value="select">Sub-theme (optional)</option>
        <option value="rough">Rough</option>
        <option value="smooth">Smooth</option>
        <option value="rocks">Rocks</option>
        <option value="plants">Plants</option>
        <option value="trees">Trees</option>
        <option value="human-made">Human-made</option>
        <option value="nature-made">Nature-made</option>
        <option value="other">Other</option>
      </select>
    );
  }

  function subthemeScale() {
    return (
      <select
        name="subtheme-scale"
        id="subtheme-scale"
        onChange={(e) => setSubTheme(e.target.value)}>
        <option value="select">Sub-theme (optional)</option>
        <option value="vast-things">Vast things</option>
        <option value="tiny-things">Tiny things</option>
        <option value="macro">Macro</option>
        <option value="found-objects">Found objects</option>
        <option value="landscape">Landscape</option>
        <option value="other">Other</option>
      </select>
    );
  }

  function subthemeNature() {
    return (
      <select
        name="subtheme-nature"
        id="subtheme-nature"
        onChange={(e) => setSubTheme(e.target.value)}>
        <option value="select">Sub-theme (optional)</option>
        <option value="flowers">Flowers</option>
        <option value="birds">Birds</option>
        <option value="insects">Insects</option>
        <option value="animals">Animals</option>
        <option value="plants">Plants</option>
        <option value="trees">Trees</option>
        <option value="rocks">Rocks</option>
        <option value="land">Land</option>
        <option value="water">Water</option>
        <option value="other">Other</option>
      </select>
    );
  }

  useEffect(() => {
    if (theme === "theme") {
      setSubThemeJSX(<></>);
    }

    if (theme === "light") {
      setSubThemeJSX(subthemeLight());
    }
    if (theme === "color") {
      setSubThemeJSX(subthemeColor());
    }
    if (theme === "texture-pattern") {
      setSubThemeJSX(subthemeTexturePattern());
    }
    if (theme === "scale") {
      setSubThemeJSX(subthemeScale());
    }
    if (theme === "nature") {
      setSubThemeJSX(subthemeNature());
    }
  }, [theme]);

  useEffect(() => {
    if (location !== null) {
      geocodeByAddress(location["label"])
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          console.log("Got coords: " + lat + "_" + lng);
          setLongitude(lng);
          setLatitude(lat);
        });
    } else {
      setLongitude(null);
      setLatitude(null);
    }
  }, [location]);

  function changed() {
    routeChange().then((t) => {
      location.reload();
    });
  }

  // Routing user to site page
  let navigate = useNavigate();
  const routeChange = () => {
    let path = "/gallery";
    navigate(path);
    return true;
  };

  function TagsInput(props) {
    const [newTagEntered, setNewTagEntered] = useState(null);

    function newTag() {
      if (newTagEntered !== null) {
        addTags(newTagEntered);
      } else {
        console.log("Must enter tag");
      }
    }

    const removeTags = (index) => {
      setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
    };
    const addTags = (event) => {
      if (event.target.value !== "") {
        setTags([...tags, event.target.value.toLowerCase()]);
        props.selectedTags([...tags, event.target.value.toLowerCase()]);
        event.target.value = "";
      }
    };
    return (
      <div className="tags-input">
        <ul>
          {tags.map((tag, index) => (
            <li key={index} className="tag">
              <span className="tag-title">{tag}</span>
              <i className="material-icons" onClick={() => removeTags(index)}>
                &nbsp;x
              </i>
            </li>
          ))}
        </ul>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="text"
            onChange={(event) => setNewTagEntered(event)}
            placeholder="Tags (optional)"
            style={{ marginRight: "10px" }}
          />
          <button
            type="button"
            onClick={() => newTag()}
            style={{
              fontSize: "0.8em",
              padding: "5px 5px",
              borderRadius: "10px",
              border: "none",
              backgroundColor: "#f5f5f5",
            }}>
            Add
          </button>
        </div>
      </div>
    );
  }

  function handleSubmit(props) {
    // Random string generator
    const randomString = Math.random().toString(36).substring(2);
    // Get current date
    const now = new Date();
    const dateString = now.toISOString().slice(0, 10); // Extract YYYY-MM-DD from ISO string
    const fileName = dateString + "_" + randomString;
    if (
      theme !== null &&
      description !== null &&
      file !== null &&
      (!showOnMap || (showOnMap && latitude !== null && longitude !== null))
    ) {
      var geoLocation = null;
      if (location) {
        geoLocation = location["label"];
      }
      setLoading(<LoadingIcons.Circles className="loadingIcon" />);
      var storageRef = ref(storage, "Awe Uploads/" + fileName);
      const uploadTask = uploadBytesResumable(storageRef, file, "data_url");
      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          alert(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((URL) => {
            setDoc(doc(db, "Awe Uploads/", fileName), {
              theme: theme,
              subtheme: subtheme,
              location: geoLocation,
              name: name,
              description: description,
              file: URL,
              longitude: longitude,
              latitude: latitude,
              directions: directions,
              tags: tags,
              dateTime: now,
              showName: showName,
              showOnMap: showOnMap,
              cityCountry: cityCountry,
            }).then(() => {
              changed();
            });
          });
        }
      );
    } else {
      setErrorMessage("One or more fields are not complete. Please try again.");
    }
  }

  function upload(e) {
    e.preventDefault();
    setFile(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  }

  function handleShowName() {
    setShowName(!showName);
  }

  function handleShowOnMap() {
    setShowOnMap(!showOnMap);
    setCityStateInput(false);
    setLocationOptionShow(!locationOptionShow);
    setButtonColorYES("#7C3224");
    setButtonColorNO("#808080");
  }

  function handleCityStateInput() {
    setCityStateInput(!cityStateInput);
    setLocationOptionShow(false);
    setButtonColorNO("#7C3224");
    setButtonColorYES("#808080");
  }

  return (
    <>
      <div className="uploadContainerFull">
        <div className="headerUpload">
          <div className="headline">Upload Your Awe</div>
          <div className="underline"></div>
          <div className="subHeadline">
            Use this form to upload your awe moment to our map and/or gallery.{" "}
            <br />
            Choose a theme and sub-theme that best describes your awe find. Add
            any <br />
            additional themes or categories as tags. Required fields marked with
            an asterisk (*)
          </div>
        </div>
        <div className="uploadContainer">
          <div className="imagePreviewContainer">
            <div className="imagePreviewBox">
              <img className="imagePreview" src={imagePreview} />
            </div>
          </div>
          {/* Form for inputs + uploading image */}
          <div className="inputsForm">
            <input
              className="chooseFile"
              type="file"
              id="media"
              onChange={upload}
              accept="video/mp4, image/png, image/jpg, image/jpeg, application/pdf"
              required
            />
            <textarea
              placeholder="Image Title or Short Description*"
              className="description"
              onChange={(e) => setDescription(e.target.value)}
              required></textarea>
            <input
              placeholder="Your Name (optional)"
              className="name"
              onChange={(e) => setName(e.target.value)}></input>

            <select
              name="Theme"
              id="theme"
              onChange={(e) => setTheme(e.target.value)}>
              <option value="theme">Theme*</option>
              <option value="light">Light</option>
              <option value="color">Color</option>
              <option value="texture-pattern">Texture/Pattern</option>
              <option value="scale">Scale</option>
              <option value="nature">Nature</option>
              <option value="other">Other</option>
            </select>

            {subThemeJSX}

            <TagsInput selectedTags={selectedTags} />

            <div id="inputWrapper" className="mapConsentStatement">
              Show on Map
            </div>
            <div className="buttonWrapper">
              <button
                className="yesNoButton"
                style={{ backgroundColor: buttonColorYES }}
                onClick={handleShowOnMap}>
                Yes
              </button>
              <button
                className="yesNoButton"
                style={{ backgroundColor: buttonColorNO }}
                onClick={handleCityStateInput}>
                No
              </button>
            </div>
            {cityStateInput && (
              <input
                placeholder="City, state, region only*"
                name="cityCountry"
                id="cityCountry"
                onChange={(e) => setCityCountry(e.target.value)}></input>
            )}
            {locationOptionShow && (
              <GooglePlacesAutocomplete
                placeholder="Enter address"
                selectProps={{
                  placeholder: "Enter address",
                  location,
                  onChange: setLocation,
                  styles: {
                    input: (provided) => ({
                      ...provided,
                      color: "#222222",
                    }),
                    option: (provided) => ({
                      ...provided,
                      color: "#222222",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "#222222",
                    }),
                    control: (provided) => ({
                      ...provided,
                      borderRadius: 25,
                      marginTop: 10,
                    }),
                  },
                }}
                fetchDetails={true}
                onFail={(error) => console.log(error)}
              />
            )}
          </div>

          <div className="errorMessage">{errorMessage}</div>

          <div className="submitContainer">
            <input
              className="submitButton"
              type="submit"
              value="Submit"
              onClick={handleSubmit}></input>
            <div>{loading}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Upload;
