import { collection, getDocs, query } from "firebase/firestore";

export const getTags = async (firestore) => {
  const collections = query(collection(firestore, "Awe Uploads"));
  const querySnapshot = await getDocs(collections);
  const tagsSet = new Set();
  querySnapshot.forEach(item => {
    const tags = item.data().tags;
    tags.forEach(tag => tagsSet.add(tag.toLowerCase())); // add lower case tag to set
  });
  return [...tagsSet];
};
