import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [isOpen, setOpen] = useState(false);
  const navRef = useRef(null);
  const history = useNavigate();

  function handleClick() {
    setOpen(false);

    if (window.location.pathname === '/home') {
      // Scroll to the top if already on home page (i.e. if in the about section of the home page)
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

  }

  function handleMenuClick(event) {
    event.stopPropagation(); // prevent click event from propagating to parent element
    setOpen(!isOpen);
  }

  function handleOutsideClick(event) {
    if (navRef.current && !navRef.current.contains(event.target) && isOpen) {
      setOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <div className="navbar">
      <div>
        <div className="nav-hamburger-container">
          <i className="fa-solid fa-bars icons" onClick={handleMenuClick}></i>
          {isOpen && (
            <>
            
            <div className="nav-options-panel" ref={navRef}>
            <i className="fa-solid fa-bars icons-inner" onClick={handleMenuClick}></i>
              <Link to="/home#top" className="navbar-button" onClick={handleClick}>
                <span className="nav-button-titles">Home</span>
              </Link>

              <Link to="/map" className="navbar-button" onClick={handleClick}>
                <span className="nav-button-titles">Map</span>
              </Link>

              <Link to="/gallery" className="navbar-button" onClick={handleClick}>
                <span className="nav-button-titles">Gallery</span>
              </Link>

              <Link to="/upload" className="navbar-button" onClick={handleClick}>
                <span className="nav-button-titles">Upload</span>
              </Link>

              <Link
                to="/#about"
                className="navbar-button"
                smooth="true"
                duration={850}
                offset={-110}
                onClick={handleClick}
              >
                <span className="nav-button-titles">About</span>
              </Link>

              <Link to="https://forms.gle/dpMcwezgJKdz4aX59" className="navbar-button" onClick={handleClick}>
                <span className="nav-button-titles">Join Newsletter</span>
              </Link>

            </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
