import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../Homepage/Homepage.css";
import "./Gallery.css";
import firebase from 'firebase/app';
import { getStorage } from "firebase/storage";
// import 'firebase/storage'; 
import { useScrollPosition } from 'use-scroll-position';


import { getFirestore, where, orderBy, collection, query,  getDocs, getDoc, doc, count, limit, startAfter, startAt, getCountFromServer } from 'firebase/firestore';

import Modal from 'react-modal';
import Navbar from '../Navbar/Navbar';
import {getTags} from "../Homepage/getTags";

// const firebase = require("firebase/app");
// require("firebase/firestore"); // Import Firestore if you haven't already

let batchsize = 16;
let lastDocument = null;
let isFirstLoad = true; // Track the initial page load
let prevTheme = ''; // Initialize prevTheme
let prevSubTheme = ''; // Initialize prevSubTheme
// const db = firebase.firestore();
let documents;

let firstLoad = true;

function Gallery(props) {

    Modal.setAppElement("body")
    // Initiating Firebase
    const firestore = getFirestore();

    // variables for image loading


    // Initiating Firebase storage
    const storage = getStorage();
    const [theme, setTheme] = React.useState('');
    const [subThemeJSX, setSubThemeJSX] = React.useState(<></>);
    const [tags, setTags] = React.useState([]);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [showLocation, setShowLocation] = React.useState(false)
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [subTheme, setSubTheme] = useState('');
    const navigate = useNavigate();


    const [selectedTag, setSelectedTag] = useState('');
    const handleTagChange = (event) => {
      setSelectedTag(event.target.value);
    };

    useEffect(() => {
      getTags(firestore).then(setTags);
      
    }, []);

    const handleKeyDown = (event) => {
        if (event.key === "ArrowLeft" && selectedIndex > 0) {
          handlePreviousImage();
        } else if (event.key === "ArrowRight" && selectedIndex < data.length - 1) {
          handleNextImage();
        }
      }
    
      const handleImageClick = (index) => {
        setSelectedImage(index);
        setSelectedIndex(index);
        setIsOpen(true);
      }
    
      const closeModal = () => {
        setIsOpen(false);
      }
    
      const handleNextImage = () => {
        if (selectedIndex < data.length - 1) {
          setSelectedIndex(selectedIndex + 1);
        }
      }
    
      const handlePreviousImage = () => {
        if (selectedIndex > 0) {
          setSelectedIndex(selectedIndex - 1);
        }
      }



    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown, false);
        return () => {
          document.removeEventListener("keydown", handleKeyDown, false);
        };
      });
    

    const modalStyle = {
        content: {
            marginTop: '75px',
            backgroundColor: '#0B2023',
        }
    }

    const [data, setData] = useState([]);
    
    const [isZoomed, setIsZoomed] = useState(false);

    const [zoomStyle, setZoomStyle] = useState({
        
        cursor: 'zoom-in',
        transform: 'none',
        transition: 'transform 0.25s ease-in-out',
      })

      const handleZoom = (event) => {
        if (!isZoomed) {
          // Get image dimensions and position
          const imgRect = event.target.getBoundingClientRect();
          const imgWidth = imgRect.width;
          const imgHeight = imgRect.height;
      
          // Calculate cursor position relative to the image
          const cursorX = event.clientX - imgRect.left;
          const cursorY = event.clientY - imgRect.top;
      
          // Calculate the transform origin as percentages
          const originX = (cursorX / imgWidth) * 100;
          const originY = (cursorY / imgHeight) * 100;
      
          // Update the zoom style with the new transform origin
          setZoomStyle({
            ...zoomStyle,
            transform: 'scale(2)',
            transformOrigin: `${originX}% ${originY}%`,
          });
      
          setIsZoomed(true);
        }
      };
      
      const handleResetZoom = () => {
        if (isZoomed) {
          setIsZoomed(false);
          setZoomStyle({
            ...zoomStyle,
            transform: 'scale(1)',
          });
        }
      };
    
      useEffect(() => {
        // Start the asynchronous operation if documentsPromise is null
        if (!documentsPromise) {
          documentsPromise = getData();
        }
      
        // Access the resolved value when needed
        documentsPromise.then((documents) => {
          documentsPromise = getData();
          // console.log(documents); // Access the resolved 'documents' array
        });
      }, []);
      

      let documentsPromise = null;


      useEffect(() => {
        // Start a new asynchronous operation whenever selectedTag, theme, or subTheme changes
        documentsPromise = getData();
      
        // You can also access the resolved value here if needed
        documentsPromise.then((documents) => {
          // console.log(documents);
        });
      
        console.log(subTheme);
      }, [selectedTag, theme, subTheme]);


const getData = async() => {

  var maxscroll = 0;
  const screenHeight = window.innerHeight;
  const screenWidth = window.innerWidth;

 


  var prev = next;
  var next = screenHeight* 0.1;
  var executed = false;
  var count = 2;
  var consto = next * 0.8;


  if (screenHeight/ screenWidth > 1.7) {
    consto = next * 15
  }

  if (subTheme == "other") {
    subTheme = "";
  }


  let q;
  if (selectedTag !== '' && theme !== '' && subTheme !== '') {
    q = query(collection(firestore, "Awe Uploads"), 
      where("tags", "array-contains", selectedTag),
      where("theme", "==", theme),
      where("subtheme", "==", subTheme),
      orderBy("dateTime")
    );
  } else if (selectedTag === '' && theme !== '' && subTheme !== '') {
    q = query(collection(firestore, "Awe Uploads"),
      where("theme", "==", theme),
      where("subtheme", "==", subTheme),
      orderBy("dateTime")
    );
  } else if (selectedTag !== '' && theme === '' && subTheme === '') {
    q = query(collection(firestore, "Awe Uploads"),
      where("tags", "array-contains", selectedTag),
      orderBy("dateTime")
    );
  } else if (selectedTag === '' && theme !== '' && subTheme === '') {
    q = query(collection(firestore, "Awe Uploads"),
      where("theme", "==", theme),
      orderBy("dateTime")
    );
  } else {
    q = query(collection(firestore, "Awe Uploads"),
      orderBy("dateTime")
    );
  }
      
  const querySnapshot = await getDocs(q);
  const documents = querySnapshot.docs.map((doc) => doc.data());
  documents.reverse();
  documentsPromise = documents;
  lastImg = 0;
  // call function to load images in viewport

  if (screenHeight/ screenWidth > 1 && screenHeight/ screenWidth < 1.6 ) {
    getVisibleImages(documents, 0, 16);
  } else {

    getVisibleImages(documents, 0, 12);

  }
  
  
  //getVisibleImages(documents, lastImg, 4, data);
  //getVisibleImages(documents, lastImg, 4, data);

  window.addEventListener("scroll", function() {
    // Get the current scroll position.
    const scrollPosition = window.scrollY;
  
    if (scrollPosition > maxscroll) {
      maxscroll = scrollPosition;
      // console.log("new max = " + maxscroll);
      // console.log("next: " + next);
  
    }
  
    
    if (maxscroll > next ) {
      
      if (count % 3 == 0) {
      next = next + consto;
      console.log("loading!");
      console.log(count);
      getVisibleImages(documents, lastImg, 2, data);
    } else {
      count++;
    }
    }
  });


  return documents;

};

// let prevArray = [];
let lastImg = 0;
const getVisibleImages = (documents, startFrom, limit) => {
 
  // do not attempt to load if there is nothing left to load
  if (startFrom >= documents.length) {
    console.log("out of documents!");
    return;
  }

  const min = Math.min(documents.length, lastImg + limit);
  const newDocuments = documents.slice(0, min);

  setData(newDocuments);

  lastImg += limit;
};




// Add an event listener for the scroll event.



/**
 * 
 * @param tag search criteria
 * @param currentTheme search criteria
 * @param currentSubTheme search criteria
 */

    
    useEffect(() => {
      document.addEventListener("keydown", handleKeyDown, false);
      return () => {
        document.removeEventListener("keydown", handleKeyDown, false);
      };
    });

    useEffect(() => {
      if (theme === '') {
        setSubThemeJSX(<></>);
      } else if (theme === 'light') {
        setSubThemeJSX(subthemeLight());
      } else if (theme === 'color') {
        setSubThemeJSX(subthemeColor());
      } else if (theme === 'texture-pattern') {
        setSubThemeJSX(subthemeTexturePattern());
      } else if (theme === 'scale') {
        setSubThemeJSX(subthemeScale());
      } else if (theme === 'nature') {
        setSubThemeJSX(subthemeNature());
      }
      setSubTheme("")



    }, [theme]);

    function subthemeLight() {
      return (
        <select name="subtheme-light" id="subtheme-light" onChange={(e => setSubTheme(e.target.value))}>
          <option value="dawn">Dawn</option>
          <option value="morning">Morning</option>
          <option value="afternoon">Afternoon</option>
          <option value="sunset">Sunset</option>
          <option value="dusk">Dusk</option>
          <option value="night-time">Night-time</option>
          <option value="shadow">Shadow</option>
          <option value="reflection">Reflection</option>
          <option value="sky">Sky</option>
          <option value="other">Other</option>
        </select>
      )
    }
  
    function subthemeColor() {
      return (
        <select name="subtheme-color" id="subtheme-color" onChange={(e => setSubTheme(e.target.value))}>
          <option value="select">Sub-theme (optional)</option>
          <option value="red">Red</option>
          <option value="orange">Orange</option>
          <option value="yellow">Yellow</option>
          <option value="green">Green</option>
          <option value="blue">Blue</option>
          <option value="purple">Purple</option>
          <option value="pink">Pink</option>
          <option value="black">Black</option>
          <option value="white">White</option>
          <option value="white">Black & White</option>
          <option value="grey">Grey</option>
          <option value="brown">Brown</option>
          <option value="multicolored">Multicolored</option>
          <option value="other">Other</option>
        </select>
      )
    }
  
    function subthemeTexturePattern() {
      return (
        <select name="subtheme-texture-pattern" id="subtheme-texture-pattern"
          onChange={e => setSubTheme(e.target.value)}
        >
          <option value="select">Sub-theme (optional)</option>
          <option value="rough">Rough</option>
          <option value="smooth">Smooth</option>
          <option value="rocks">Rocks</option>
          <option value="plants">Plants</option>
          <option value="trees">Trees</option>
          <option value="human-made">Human-made</option>
          <option value="nature-made">Nature-made</option>
          <option value="other">Other</option>
  
        </select>
      )
    }
  
    function subthemeScale() {
      return (
        <select name="subtheme-scale" id="subtheme-scale" onChange={(e => setSubTheme(e.target.value))}>
          <option value="select">Sub-theme (optional)</option>
          <option value="vast-things">Vast things</option>
          <option value="tiny-things">Tiny things</option>
          <option value="macro">Macro</option>
          <option value="found-objects">Found objects</option>
          <option value="landscape">Landscape</option>
          <option value="other">Other</option>
        </select>
      )
    }
  
    function subthemeNature() {
      return (
        <select name="subtheme-nature" id="subtheme-nature" onChange={(e => setSubTheme(e.target.value))}>
          <option value="select">Sub-theme (optional)</option>
          <option value="flowers">Flowers</option>
          <option value="birds">Birds</option>
          <option value="insects">Insects</option>
          <option value="animals">Animals</option>
          <option value="plants">Plants</option>
          <option value="trees">Trees</option>
          <option value="rocks">Rocks</option>
          <option value="land">Land</option>
          <option value="water">Water</option>
          <option value="other">Other</option>
        </select>
      )
    }

    function handleThemeChange(e) {
      setTheme(e.target.value);
    }
    
    return (
        <>
        <div className="contentBox">
						  <div className="container_h">
                <div className="galleryTitleWrapper">
							    <div className="galleryTitle">Awe Gallery</div>
                  <div className="underlineGallery"></div>

                  <div className="filter">

                  <div className="filter-select">
                      <select className="theme-select" id="theme-select" value={theme} onChange={handleThemeChange}>
                        <option value="">All themes</option>
                        <option value="light">Light</option>
                        <option value="color">Color</option>
                        <option value="texture-pattern">Texture & Pattern</option>
                        <option value="scale">Scale</option>
                        <option value="nature">Nature</option>
                      </select>
                      {subThemeJSX}
                    </div>

                    <div className="filter-select">
                      <select id="tag-select" value={selectedTag} onChange={handleTagChange}>
                        <option value="">All tags</option>
                        {tags.map((tag, index) => (
                          <option key={index} value={tag}>
                            {tag.replace(/^\w/, c => c.toUpperCase())}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  </div>
                </div>
              </div>

              <div className="imgList">
                <ul className="clearfix">
                <div className="gallery">
                  {data.map((doc, index) => (
                    <div className="gallery-item" key={index}>
                      <img src={doc.file} alt={doc.title} onClick={() => handleImageClick(index)} />
                    </div>
                  ))}
              </div>
          
              <Modal style={modalStyle} isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Enlarged Image">
                <div className="modal-container">
                  <div className="modal-image-container">
                      <img
                      style={zoomStyle}
                      onClick={isZoomed ? handleResetZoom : (event) => handleZoom(event)}
                      src={data[selectedIndex]?.file}
                      alt="Enlarged Image"
                      />
                  </div>
                  <div className="modal-details-container">
                      <div className="modal-buttons-container">
                          <button className="modal-button" onClick={handlePreviousImage}>Back</button>
                          <button className="modal-button" onClick={handleNextImage}>Next</button>
                      </div>
                      <div className="modal-description">{data[selectedIndex]?.description}</div>
                      {data[selectedIndex]?.name && 
                      <div className="modal-address">{data[selectedIndex]?.name}</div>
                      }
                      {data[selectedIndex]?.location && 
                      <div className="modal-address">{data[selectedIndex]?.location}</div>
                      }
                      {data[selectedIndex]?.dateTime && 
                      <div className="modal-date-time">{new Date(data[selectedIndex]?.dateTime.seconds * 1000).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                      })}</div>
                      }

                      {data[selectedIndex]?.tags &&
                      <>
                      <div className="modal-tags-wrapper">
                      {data[selectedIndex]?.tags.map((tag, index) => (
                        <div className="modal-tag" key={index}>{tag}&nbsp;</div>
                      ))}
                      </div>
                      
                      </>
                       }
                  </div>

                  <div className="modal-close" onClick={closeModal}>
                  <span className="x">&times;</span>
                  </div>



              </div>
            </Modal>   
            </ul>

            

          </div>
        </>
      );
    }

export default Gallery;