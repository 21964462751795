import React, { useEffect, useState, Suspense, useRef } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import "./Homepage.css";
import emailjs from "@emailjs/browser";
import gubnerPhoto from "./Images/gubner.jpeg";
import sydneyPhoto from "./Images/sydney.jpeg";

function Homepage(props) {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
    }
  }, [location]);

  const [formSubmitButtonValue, setFormSubmitButtonValue] = useState("");
  const [formVal, setFormVal] = useState();
  // Sending comment to email using Emailjs, https://www.emailjs.com/docs/examples/reactjs/
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_a2irjrp",
        "template_4ppau7bgrbsdfvd",
        form.current,
        "bqek4OFlM1dim_T_G"
      )
      .then(
        (result) => {
          setFormSubmitButtonValue("Successfully submitted!");
          setFormVal("");

          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const [pageName, setPageName] = useState("");
  function handleClick(event) {
    setPageName(event.target.value);
  }
  useEffect(() => {
    routeChange();
  }, [pageName]);

  // Routing user to site page
  let navigate = useNavigate();
  const routeChange = () => {
    let path = "/" + pageName.replace(/\s+/g, "");
    navigate(path);
  };

  return (
    <>
      <div className="header-wrapper">
        <div className="header"></div>
      </div>

      <div className="container">
        <div className="content">
          <div className="buttonsContainer">
            <Link className="navButton" to="/gallery">
              <i className="fa-solid fa-image">
                <span className="navButtonTitlesHome">GALLERY</span>
              </i>
            </Link>
            <Link className="navButton" to="/map">
              <i className="fa-solid fa-map-location-dot">
                <span className="navButtonTitlesHome">MAP</span>
              </i>
            </Link>
            <Link className="navButton" to="/upload">
              <i className="fa-solid fa-cloud-arrow-up">
                <span className="navButtonTitlesHome">UPLOAD</span>
              </i>
            </Link>
          </div>
          <span className="aboutSpan" id="about"></span>
          <div className="borders"></div>

          <div className="aboutTitle">About</div>
          <div className="underlineHome"></div>
          {/* ------------------------------------ */}
          <span>
            <div className="split">
              <div>
                <div className="left">
                  <p className="leftText">What is Awe Walking?</p>
                </div>
              </div>

              <div>
                <div className="right">
                  Awe walking is a simple wellness practice that involves going
                  on walks and shifting your attention outward toward things
                  that inspire awe. Awe walks can be as long as you like, but we
                  encourage you to walk for at least 15 minutes. If you have
                  limited mobility, feel free to look for awe in your backyard
                  or outside your bedroom window.
                </div>
              </div>
            </div>
            <div className="partition"></div>
          </span>
          {/* ------------------------------------ */}
          <span>
            <div className="split">
              <div>
                <div className="left">
                  <p className="leftText">What is The Awe Collective?</p>
                </div>
              </div>

              <div>
                <div className="right">
                  The Awe Collective was created as a platform for creatively
                  gathering, connecting, and mapping moments of awe. Our goal is
                  to promote the wellness practice of awe walking by giving
                  users a place to experience and share these moments with one
                  another. We welcome you to browse the awe gallery and map for
                  inspiration, and then to contribute and share this project
                  with others to help us cultivate an online wellness community
                  rooted in creative expression and childlike wonder.
                </div>
              </div>
            </div>
            <div className="partition"></div>
          </span>

          <span>
            <div className="split">
              <div>
                <div className="left">
                  <p className="leftText">Why Should I Go On Awe Walks?</p>
                </div>
              </div>

              <div>
                <div className="right">
                  In 2020, UCSF Neuroscientist{" "}
                  <a
                    className="hyperlink"
                    href="https://memory.ucsf.edu/people/virginia-sturm-phd"
                    target="_blank">
                    Dr. Virginia Sturm
                  </a>{" "}
                  published a{" "}
                  <a
                    className="hyperlink"
                    href="https://www.ucsf.edu/news/2020/09/418551/awe-walks-boost-emotional-well-being"
                    target="_blank">
                    study
                  </a>{" "}
                  showing that going on short walks looking for the emotion of
                  awe can promote brain health in healthy older adults. After
                  sending a group of 52 randomized participants on awe walks and
                  regular walks over the course of 8 weeks, the study{" "}
                  <a
                    className="hyperlink"
                    href="https://pubmed.ncbi.nlm.nih.gov/32955293/"
                    target="_blank">
                    found
                  </a>{" "}
                  that awe walkers experienced more joy during their walks, as
                  well as heightened prosocial positive emotions (things like
                  compassion, admiration, and gratitude) and a decrease in
                  stress in their daily lives in general. While her study
                  focused on older adults, the benefits of awe walking can be
                  experienced{" "}
                  <a
                    className="hyperlink"
                    href="https://www.timeforkids.com/g56/walking-is-awesome-2/"
                    target="_blank">
                    at any age
                  </a>
                  .
                </div>
              </div>
            </div>
            <div className="partition"></div>
          </span>

          {/* ------------------------------------ */}
          <span>
            <div className="split">
              <div>
                <div className="left">
                  <p className="leftText">
                    How Can Thematic Walks Help Me Tune Into Awe?
                  </p>
                </div>
              </div>

              <div>
                <div className="right">
                  Like other wellness practices, awe walking is a skill, and at
                  first it can feel a little intimidating. While extraordinary
                  places like the Grand Canyon inspire awe, we can also feel awe
                  when looking at small everyday things like a flower petal or
                  an insect. Going on awe walks that focus on a given
                  awe-inspiring theme (for example light, colors, tiny things,
                  patterns, or textures) can help you train your senses to tune
                  into awe in everyday surroundings. We encourage you to explore
                  the different themes in our awe gallery and then try out your
                  own thematic walks. Perhaps focus on light on a late afternoon
                  stroll, or colors—or a single color—on a spring walk in a
                  garden. Then try repeating the same walk a different day with
                  a new focus, perhaps looking instead for textures and
                  patterns, or tiny things.
                  <br />
                  Scientists have found that awe inspires awe, so the more you
                  learn to tap into this emotion, the more awe-inspiring things
                  you should notice around you, and the better you should feel!
                  Choosing creative themes for your awe walks can also help you
                  stay engaged with this practice over time by giving you tools
                  to explore your surroundings with fresh eyes and ways to
                  connect with others who search for similar themes.
                </div>
              </div>
            </div>
            <div className="partition"></div>
          </span>

          {/* ------------------------------------ */}
          <span>
            <div className="split">
              <div>
                <div className="left">
                  <p className="leftText">
                    How Can I Contribute to the Awe Collective?
                  </p>
                </div>
              </div>

              <div>
                <div className="right">
                  The easiest way to contribute to this project is by uploading
                  photos of awe-inspiring things found on your walks. On the
                  upload section of the website, select an image to share, give
                  it a title or short description, and indicate a theme,
                  sub-theme, and any additional tags that will help connect your
                  moment of awe to those of others. We also welcome you to use
                  other creative forms of expression to process and share
                  moments of awe (maybe bring a sketchbook on your walk, turn a
                  photo into a watercolor painting, write a haiku, or document a
                  collection of small objects). Remember, the point of the
                  platform is not to show perfect photographs or drawings, but
                  to encourage one another to find creative ways to tap into and
                  experience moments of awe in our everyday lives. Try to
                  silence your inner perfectionist and connect instead with your
                  inner child as you playfully document, create and share
                  everyday moments of wonder.
                </div>
              </div>
            </div>
            <div className="partition"></div>
          </span>

          {/* ------------------------------------ */}
          <span>
            <div className="split">
              <div>
                <div className="left">
                  <p className="leftText">
                    How Can I Bring Awe Walking to My Community?
                  </p>
                </div>
              </div>

              <div>
                <div className="right">
                  <b>1.</b> One great way to build an awe walking practice in
                  your community is by creating a month-long or ongoing{" "}
                  <a
                    className="hyperlink"
                    href="https://www.artscabinet.org/encounters/invitation-to-an-awe-walk"
                    target="_blank">
                    awe walking group
                  </a>{" "}
                  with friends, family or co-workers. To do this, form a group,
                  choose some themes and/or artistic modalities to inspire your
                  walks (ie color and drawing or light and photography),
                  designate a leader to send out one theme a week, and share
                  your awe walk finds here to the gallery and map!
                  <br />
                  <br />
                  <b>2.</b> At the University of Arizona, we have been hosting a
                  Workshop Series called
                  <a
                    className="hyperlink"
                    href="https://healthsciences.arizona.edu/tomorrow/0323/promoting-health-inspiring-awe-aging-adults-0"
                    target="_blank">
                    {" "}
                    “Creative Encounters in Awe”
                  </a>{" "}
                  since Fall of 2022. In the series we host creative awe walking
                  events in partnership with community organizations and
                  creative professionals. Please be in touch if you would like
                  to learn more about this program and how we might bring this
                  series to your community.
                </div>
              </div>
            </div>
            <div className="partition"></div>
          </span>

          {/* ------------------------------------ */}

          <span>
            <div className="split">
              <div>
                <div className="left">
                  <p className="leftText">Who Made This Website and Why?</p>
                </div>
              </div>

              <div>
                <div className="right">
                  This website is part of a larger project created by Dr. Jennie
                  Gubner (left) and one of her doctoral mentees, Sydney
                  Streightiff (right), from the University of Arizona Applied
                  Intercultural Arts Research (AIAR) Graduate Interdisciplinary
                  Program. As applied and socially engaged arts researchers,
                  they are interested in finding ways to use arts and creativity
                  to promote accessible healthy aging practices and to build
                  intergenerational, age-friendly communities. Having met and
                  been inspired by Dr. Sturm's work while an Atlantic Fellow for
                  Equity in Brain Health at the Global Brain Health Institute ,
                  Dr. Gubner invited Sydney to work with her to explore how
                  creative and artistic prompts might offer new ways to
                  encourage people of all ages to learn about, and engage in awe
                  walking practices. This website is one of the many projects
                  emerging from their{" "}
                  <a
                    className="hyperlink"
                    href="https://www.artscabinet.org/contributors/jennie-gubner"
                    target="_blank">
                    collaboration
                  </a>
                  .
                </div>
                <div className="imagesContainerProfile">
                  <div className="profileWrapper">
                    <img className="profileImages" src={gubnerPhoto} />
                    <p>Jennie Gubner</p>
                  </div>
                  <div className="profileWrapper">
                    <img className="profileImages" src={sydneyPhoto} />
                    <p>Sydney Streightiff</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="partition"></div>
          </span>

          {/* ------------------------------------ */}

          <span>
            <div className="split">
              <div>
                <div className="left">
                  <p className="leftText">Acknowledgments</p>
                </div>
              </div>

              <div>
                <div className="right">
                  We would like to thank University of Arizona Health Sciences
                  Innovations in Healthy Aging for providing us with financial
                  support to create this website and organize the inaugural
                  Creative Encounters in Awe Workshop series through the UAHS
                  Aging and the Arts Initiative. We would also like to thank Dr.
                  Bryan Carter and the coders at the University of Arizona
                  Center for Digital Humanities for bringing this website to
                  life. Thank you in particular to Duo Bao and especially Josh
                  Tornquist for his tireless efforts in realizing our vision.
                  This project would also not have been possible without the
                  creative and intellectual support of the Erasmus + Strategic
                  Partnership Encounters through Arts, Ethnography and Pedagogy
                  that provided us with an international research community with
                  whom to develop our ideas, and Arts Cabinet London their
                  creative arts research publication platform where we published
                  the initial findings from this exploration. Lastly, we would
                  like to thank Dr. Virginia Sturm, her research team, and the
                  Global Brain Health Institute, for inspiring us to think big
                  and in creative, interdisciplinary ways about how to promote
                  accessible and affordable pathways toward brain health and
                  wellness across the lifespan.
                </div>
              </div>
            </div>
            <div className="partition"></div>
          </span>

          {/* COMMENT FORM */}
          <div className="formWrapper">
            <div className="commentFormTitle">Get in touch</div>
            <div className="underlineHomeComment"></div>
            <div className="commentForm">
              <form ref={form} onSubmit={sendEmail}>
                <input
                  type="text"
                  name="user_name"
                  value={formVal}
                  className="formInput"
                  placeholder="Full name"
                />
                <input
                  type="email"
                  name="user_email"
                  value={formVal}
                  className="formInput"
                  placeholder="Email"
                />
                <textarea
                  name="message"
                  value={formVal}
                  className="formInputDescription"
                  placeholder="Comments or questions"
                />
                <input
                  id="formSubmitButton"
                  className="formSubmitButton"
                  type="submit"
                  value="Submit"
                />
                <div className="formSubmitButtonValue">
                  {formSubmitButtonValue}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Homepage;
